import AccessTimeIcon from '@mui/icons-material/AccessTime';
import OutlinedFlagIcon from '@mui/icons-material/OutlinedFlag';
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './CardPrice.module.css';

export default function CardPrice({
  price,
  duration,
  language,
  logo,
  programId,
  hideEnrollButton = false,
}) {
  const redirectPage = localStorage.getItem('accessToken')
    ? programId === 13 || programId === 12
      ? `/Fawry-payment/${programId}`
      : `/payment-plans/${programId}`
    : '/login';

  // Original prices for discount calculation
  const originalPrice = programId === 12 ? 1800 : null;

  return (
    <div
      className={`${styles.cardPriceContainer} ${
        hideEnrollButton ? styles.noButton : ''
      }`}
    >
      <div className='flex justify-between items-center'>
        <h4>Tuition fees</h4>
        {programId === 12 && (
          <div className='flex'>
            <h2 className='!text-white font-normal !text-sm px-3 py-2 rounded-l-full bg-black'>
              Discount
            </h2>
            <h2 className='!text-white font-normal !text-sm px-3 py-2 rounded-r-full bg-[#40A353]'>
              10%
            </h2>
          </div>
        )}
      </div>
      {programId === 12 || programId === 13 ? (
        <div className='flex flex-col items-start'>
          {originalPrice && (
            <span className='line-through text-gray-500'>
              {originalPrice} EGP
            </span>
          )}
          <h2 className='text-2xl text-[#40A353] font-bold'>{price} EGP</h2>
        </div>
      ) : (
        <h2>
          {price} {programId === 12 || programId === 13 ? 'EGP' : 'USD'}
        </h2>
      )}
      {!hideEnrollButton && (
        <Link to={redirectPage} className={styles.enrollBtn}>
          Enroll Now
        </Link>
      )}
      <div className={styles.divInCard}>
        <AccessTimeIcon />
        <h2>Course Duration</h2>
        <p>{duration}</p>
      </div>
      <div className={styles.divInCard}>
        <OutlinedFlagIcon />
        <h2>Language</h2>

        <p>{language}</p>
      </div>
      {programId === '12' && programId === '13' && (
        <p>
          Certificate accredited by: <img src={logo} alt='companyLogo' />
        </p>
      )}
    </div>
  );
}
