import { Link } from 'react-router-dom';
import arrowRight from '../../../assets/arrowRight.svg';
import { useGetProgramPaymentStatus } from '../../../hooks/useGetProgramPaymentStatus';
import styles from '../AllPrograms.module.css';

const Program = ({ program }) => {
  const paymentStatus = useGetProgramPaymentStatus(program.id);
  const price = paymentStatus?.program_price;

  return (
    <div className={styles.content}>
      <div className={styles.textSection}>
        <div className='flex justify-between items-center'>
          <img src={program.logo} alt={`programLogo`} />
          {/* Discount section only for programs 12 and 13 */}
          {program.id === 12 && (
            <div className='flex'>
              <h2 className='!text-white font-normal !text-sm px-3 py-2 rounded-l-full bg-black'>
                Discount
              </h2>
              <h2 className='!text-white font-normal !text-sm px-3 py-2 rounded-r-full bg-[#40A353]'>
                10%
              </h2>
            </div>
          )}
        </div>
        <section>
          <h2>{program.name}</h2>
          <p className='programDescription'>{program.description}</p>
        </section>
        <div className={styles.priceSection}>
          {/* Price section with line-through for programs 12 and 13 */}
          {price && (
            <span className='flex flex-col items-center space-y-1'>
              {program.id === 12 && (
                <>
                  <span className='line-through text-gray-500'>1800 EGP</span>
                  <span className='text-xl text-[#40A353] font-bold'>
                    {price} EGP
                  </span>
                </>
              )}
              {program.id === 13 && (
                <>
                  <span className='text-xl text-[#40A353] font-bold'>
                    {price} EGP
                  </span>
                </>
              )}
              {program.id !== 12 && program.id !== 13 && (
                <span className='text-xl text-[#40A353] font-bold'>
                  {price} USD
                </span>
              )}
            </span>
          )}
        </div>
        <Link className={styles.readMoreBtn} to={program.pageRoute}>
          <img src={arrowRight} alt='arrowRight' />
          Read more
        </Link>
      </div>
      <div className={styles.motoContainer}>
        <img
          className={styles.program_image}
          src={program.rightImage}
          alt='programMoto'
        />
      </div>
    </div>
  );
};

export default Program;
